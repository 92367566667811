<template>
  <a-modal
    :visible="visibleSubscriptionModal"
    :footer="null"
    @cancel="closeModal"
    class="subscription-packages-modal"
  >
    <div class="subscription-packages-modal-container">
      <div class="subscription-packages-modal-container__header">
        <img src="@/assets/images/logo@2x.webp" alt="logo" />
      </div>
      <div class="subscription-packages-modal-container__body">
        <h2 class="subscription-packages-modal-container__body--title">
          You are using Glocal Freemium!
        </h2>
      </div>
      <div class="subscription-packages-modal-container__footer">
        <h3 class="subscription-packages-modal-container__footer--title">
          Subscribe to Glocal Premium to get full access!
        </h3>
        <div class="subscription-packages-modal-container__footer--package">
          <p class="title">Starting from</p>
          <div class="price">
            <h4>$ 5.00</h4>
            <p>/ Month</p>
          </div>
        </div>
        <button
          @click="viewPackagesModal"
          class="subscription-packages-modal-container__footer--btn"
        >
          View package details
        </button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visibleSubscriptionModal: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    viewPackagesModal() {
      this.$store.commit("auth/setSubscriptionPackagesVisibilty", true);
    },
  },
};
</script>

<style lang="scss">
.subscription-packages-modal {
  width: 60.1rem !important;
  top: 15.5rem !important;
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close {
        margin: 2rem 2rem 0 0 !important;
        .ant-modal-close-x {
          .anticon {
            color: $color-white !important;
          }
        }
      }
    }
    .ant-modal-body {
      .subscription-packages-modal-container {
        border-radius: 1rem;
        &__header {
          background-image: url("../../../assets//images/welcome-modal-background.png");
          height: 17.2rem;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          border-start-end-radius: 1rem;
          border-start-start-radius: 1rem;
          img {
            width: 5.1rem;
            height: auto;
          }
        }
        &__body {
          padding: 3.2rem 2.4rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.219);
          &--title {
            font-family: $font-primary-regular;
            font-weight: 600;
            font-size: 2.8rem;
            text-align: center;
            line-height: 3.6rem;
            color: $color-black;
            margin-bottom: 0;
            opacity: 1;
          }
        }
        &__footer {
          padding: 3rem 2.3rem;
          &--title {
            font-family: $font-primary-medium;
            font-size: 2rem;
            text-align: center;
            line-height: 3rem;
            color: $color-black;
            opacity: 1;
            margin-bottom: 0;
          }
          &--package {
            margin: 2rem auto 3.2rem;
            width: fit-content;
            display: block;
            .title {
              font-family: $font-primary;
              font-size: 1.4rem;
              text-align: center;
              line-height: normal;
              color: $color-black;
              margin-bottom: 0;
              opacity: 1;
            }
            .price {
              display: flex;
              align-items: center;
              margin-top: 0.3rem;
              h4 {
                font-family: $font-primary-bold-2;
                font-size: 4rem;
                text-align: center;
                line-height: normal;
                color: $color-primary;
                margin-bottom: 0;
              }
              p {
                font-family: $font-primary-bold;
                font-size: 1.6rem;
                text-align: center;
                line-height: normal;
                color: $color-black;
                margin-bottom: 0;
                margin-left: 0.3rem;
                opacity: 1;
              }
            }
          }
          &--btn {
            background-color: $color-primary;
            font-family: $font-primary-medium;
            font-size: 1.6rem;
            height: 4.8rem;
            width: 100%;
            border: none;
            outline: none;
            color: $color-white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
